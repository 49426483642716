<template>
  <layout-component>
    <div class="lunch">
      <div class="lunch__header">
        <h1 class="lunch__header__title">Lunch</h1>
        <div class="lunch__header__buttons">
          <button-component @click="date = previousDay(date)"><i class="fa-solid fa-angle-left"></i></button-component>
          <button-component @click="date = today()">{{ dateDisplay }}</button-component>
          <button-component @click="date = nextDay(date)"><i class="fa-solid fa-angle-right"></i></button-component>
        </div>
      </div>
      <template v-if="lunch">
        <div class="lunch__day">
          <meal-component :day="lunch"></meal-component>
        </div>
      </template>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed, watch } from "vue";

import { apiService } from "../bootstrap";

import { ILunchDayDTO } from "../../../shared/dto/lunch-day.dto";
import { dateFormat, weekDay } from "../../../shared/utils/date.util";

import LayoutComponent from './layout.component.vue';
import MealComponent from "./meal.component.vue";
import ButtonComponent from "./button.component.vue";

const lunch = ref<ILunchDayDTO>();
const date = ref<Date>(today());

const dateDisplay = computed(() => {
  const month = date.value.getMonth() + 1;
  const day = date.value.getDate();
  return `${weekDay(date.value)} ${day}/${month}`;
});

function previousDay(date: Date): Date {
  const temp = new Date(date);
  temp.setDate(temp.getDate() - 1);
  if(temp.getDay() === 6) {
    temp.setDate(temp.getDate() - 1)
  } else if(temp.getDay() === 0) {
    temp.setDate(temp.getDate() - 2)
  }
  return temp;
}

function today() {
  const date = new Date();
  if(dateIsAfter4PM(date) || date.getDay() === 6 || date.getDay() === 0) {
    return nextDay(date);
  }
  return date;
}

function dateIsAfter4PM(date: Date) {
  return date.getHours() >= 16;
}

function nextDay(date: Date) {
  const temp = new Date(date);
  temp.setDate(temp.getDate() + 1);
  if(temp.getDay() === 6) {
    temp.setDate(temp.getDate() + 2)
  } else if(temp.getDay() === 0) {
    temp.setDate(temp.getDate() + 1)
  }
  return temp;
}

async function getLunch(date: Date): Promise<ILunchDayDTO> {
  return (await apiService.get<ILunchDayDTO>(`/lunch?date=${dateFormat(date)}`)).body;
}

watch(date, async () => {
  lunch.value = await getLunch(date.value);
})

onMounted(async () => {
  lunch.value = await getLunch(date.value);
})
</script>

<style lang="scss" scoped>
.lunch {
  &__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    &__title {
      width: 30%;
    }
    &__buttons {
      width: 70%;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  &__day {
    width: 100%;
  }
}
</style>