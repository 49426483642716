<template>
  <div class="alert">
    <h2>{{ message }}</h2>
    <button-component @click="$emit('close')">Close</button-component>
  </div>
</template>
<script lang="ts" setup>
import buttonComponent from './button.component.vue';
defineProps<{
  message: string;
}>();
</script>
<style lang="scss" scoped>
  @import '../../scss/base';
  .alert {
    padding: 20px;
    text-align: center;
  }
  </style>