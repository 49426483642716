// Libs
import Axios from "axios";
import { detect } from "detect-browser";

// Services
import { StorageService } from "./services/storage.service";
import { BroadcastService } from "../../shared/services/broadcast.service";
import { APIService } from "../../shared/services/api.service";
import { BrowserService } from "./services/browser.service";
import { ModalService } from "./services/modal.service";

// Components
import ModalBaseComponent from "./components/modal-base.component.vue"

export const axios = Axios.create();
export const browserService = new BrowserService(window, detect);
axios.defaults.baseURL = process.env.API_BASE_URL;
export const broadcastService = new BroadcastService();
export const storageService = new StorageService("storage", new BroadcastService());
export const apiService = new APIService(process.env.API_BASE_URL || "", axios, new BroadcastService());
export const modalService = new ModalService(document, ModalBaseComponent);