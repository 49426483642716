import { BotInfo, BrowserInfo, detect, NodeInfo, ReactNativeInfo, SearchBotDeviceInfo } from "detect-browser";

export class BrowserService {
  private browser: BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo | null = null;
  public constructor(
    private readonly window: Window,
    detectBrowser: typeof detect,
  ) {
    this.browser = detectBrowser(window.navigator.userAgent);
  }

  public isSecure(): boolean {
    return this.window.location.protocol === "https:";
  }

  public get name(): string | null {
    return this.browser !== null ? this.browser.name : null;
  }

  public get version(): string | null {
    return this.browser !== null ? this.browser.version : null;
  }

  public get isIncompatible(): boolean {
    if (this.browser === null || this.name === null || this.version === null) {
      return true;
    }
    return this.name === "ie" || (this.name === "safari" && parseInt(this.version, 10) < 10);
  }

  public get isIos(): boolean {
    return this.browser !== null && this.browser.os === "iOS";
  }
  public get isAndroid(): boolean {
    return this.browser !== null && this.browser.os === "Android OS";
  }

  public get isDesktop(): boolean {
    return !(this.isIos || this.isAndroid);
  }
  public get isHandheld(): boolean {
    return !this.isDesktop;
  }

  public get language(): string {
    return this.window.navigator.language;
  }
}
