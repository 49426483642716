<template>
  <div class="modal">
    <div class="modal__container">
      <component
        :is="component"
        v-bind="compProps"
        @hide="hide"
        @close="close"
        @error="error"
        @event="event"
      ></component>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { onMounted, ref } from "vue";
import { BroadcastService } from "../../../shared/services/broadcast.service";

// Services
import { componentType, componentProps } from "../modules/modal.module";

const p = defineProps<{
  component: componentType;
  broadcastService: BroadcastService;
  props?: componentProps<componentType>;
}>();

const compProps = ref<unknown>({ ...p.props });

onMounted(() => {
  // Subscribe to property changes
  p.broadcastService.on<componentProps<componentType>>(
    "setProps",
    (payload) => {
      if (payload === undefined) return;
      // Update component properties
      compProps.value = { ...p.props.value, ...payload };
    }
  );
});

function close(payload: unknown): void {
  p.broadcastService.emit("close", payload);
}
function hide(): void {
  p.broadcastService.emit("hide");
}
function error(error: unknown): void {
  p.broadcastService.emit("error", error);
}
function event(eventName: string, payload?: unknown): void {
  p.broadcastService.emit(eventName, payload);
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.modal {
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    @include container(true);
    margin: 0px 20px;
    min-height: 50vh;
    background-color: #fff;
  }
}
</style>
