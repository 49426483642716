export function ISO8601WeekNo(date: Date): number {
const tdt = new Date(date.valueOf());
const dayn = (date.getDay() + 6) % 7;
tdt.setDate(tdt.getDate() - dayn + 3);
const firstThursday = tdt.valueOf();
tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) 
  {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
  }
  return 1 + Math.ceil((firstThursday - tdt.getTime()) / 604800000);
}

export function dateTimeToTime(date: Date): string {
    const hour = date.getHours();
    const minute = date.getMinutes();

    return `${padLeft(hour, 2)}:${padLeft(minute, 2)}`;
}

export function dateFormat(date: Date): string {
  return `${date.getFullYear()}-${padLeft(date.getMonth() + 1, 2)}-${padLeft(date.getDate(), 2)}`;
}

export function weekDay(date: Date): string {
  const days = ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"];
  return days[date.getDay()];
}

function padLeft(value: number | string, length: number, filler = "0"): string {
  if(typeof value === "number") {
    value = value.toString();
  }

  return value.padStart(length, filler);
}