<template>
  <button :class="['button', `button--${buttonType}`, { 'button--disabled': disabled}]" @click="$emit('click')">
    <slot></slot>
  </button>
</template>
<script lang="ts" setup>
import { withDefaults } from "vue";

const $emit = defineEmits(["click"]);

const props = withDefaults(defineProps<{
  buttonType?: "primary" | "secondary" | "success";
  disabled?: boolean
}>(), {
  buttonType: "primary",
  disabled: false
});
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.button {
  margin: 0px;
  padding: 5px 10px;
  height: 40px;
  background-color: transparent;
  border: 0px;
  text-transform: uppercase;
  cursor: pointer;

  border: 1px solid #000;
  border-radius: 3px;
  background-color: $primary;
  color: #fff;

  &--primary {
    background-color: $primary;
  }
  &--secondary {
    background-color: $secondary;
  }
  &--success {
    background-color: $success;
  }
  &--disabled {
    cursor: not-allowed;
    background-color: #ccc;
    color: #888;
  }
}
</style>