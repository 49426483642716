<template>
  <div class="closes-stations">
    <div class="closes-stations__header">
      <h2 class="closes-stations__header__title">Closest station</h2>
    </div>
    <div class="closes-stations__content">
      <div class="closes-stations__content__station" v-for="(station, index) of stations" :key="`station_${index}`" @click="pickStation(station)">
        {{ station.name }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { ISLStationDTO } from "../../../shared/dto/sl-station.dto";
import { apiService, browserService } from "../bootstrap";

const $emit = defineEmits(['close'])

const stations = ref<ISLStationDTO[]>([])

onMounted(async () => {
  const position = await getPosition();
  stations.value = await getStations(position.lat, position.lon);
})

async function getPosition(): Promise<{ lat: string, lon: string }> {
  if(!browserService.isSecure()) {
    return {
      lat: '59.37887738848374',
      lon: '17.93905928289646'
    }
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition((position) => {
      resolve({
        lon: position.coords.longitude.toString(),
        lat: position.coords.latitude.toString()
      });
    }, (error) => {
      reject(error);
    });
  });
}

async function getStations(lat: string, lon: string) {
  return (await apiService.get<ISLStationDTO[]>(`/sl/stations?lat=${lat}&lon=${lon}`)).body;
}

function pickStation(station: ISLStationDTO) {
  $emit('close', station);
}
</script>
<style lang="scss" scoped>
  .closes-stations {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      background-color: #eee;
      &__title {
        margin: 10px 0px;
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
    &__content {
      &__station {
        padding: 10px;
        border-bottom: 1px solid #eee;
        text-align: center;
      }
    }
  }
</style>