<template>
  <div class="footer">
    <router-link v-for="(item, index) of menu" class="nav-link" active-class="nav-link--selected" :to="item.route" :key="`menu_item_${index}`">{{ item.title }}</router-link>
  </div>
</template>

<script lang="ts" setup>
import { RouteLocationRaw } from "vue-router";
import router from "../router";

interface IMenu {
  route: RouteLocationRaw;
  title: string;
}
const menu = [
  { route: { name: "lunch" }, title: "Lunch" },
  { route: { name: "sl" }, title: "SL" },
];

/*let tabIndex = 0;

setInterval(async () => {
  tabIndex++;
  if(tabIndex >= menu.length) {
    tabIndex = 0;
  }
  await router.push(menu[tabIndex].route);
}, 5000);*/
</script>

<style lang="scss" scoped>
  .footer {
    display: flex;
    background-color: #000;
  }
  .nav-link {
    display: block;
    font-size: 28px;
    color: #fff;
    text-decoration: none;
    padding: 20px 40px;
    &:hover, &--selected {
      background-color: #222;
      color: rgb(42, 170, 255);
    }
  }
</style>