<template>
  <div class="meal">
    <div class="meal__day">
    {{ getDayOfWeek(day.date) }}<br />
    {{ day.date }}
    </div>
    <div class="meal__content">
      <div class="meal__content__meal" v-for="(meal, mealIndex) of day.meals" :key="`${day.date}_meal_${mealIndex}`">
        <div class="meal__content__meal__value">
          {{ meal.value }}
        </div>
        <div class="meal__content__meal__attributes">
          <img class="meal__content__meal__attributes__attribute" v-for="(attribute, index) of meal.attributes" :key="`${day.date}_meal_${mealIndex}_${index}`" :src="getImage(attribute)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import beef from "../../resources/img/meal-attributes/beef.png";
import chicken from "../../resources/img/meal-attributes/chicken.png";
import fish from "../../resources/img/meal-attributes/fish.png";
import heavyLactos from "../../resources/img/meal-attributes/heavy_lactos.png";
import lowCo from "../../resources/img/meal-attributes/low_co.png";
import mediumCo from "../../resources/img/meal-attributes/medium_co.png";
import msc from "../../resources/img/meal-attributes/msc.png";
import organic from "../../resources/img/meal-attributes/organic.png";
import pork from "../../resources/img/meal-attributes/pork.png";
import swedish from "../../resources/img/meal-attributes/swedish.png";
import vegan from "../../resources/img/meal-attributes/vegan.png";
import vegetarian from "../../resources/img/meal-attributes/vegetarian.png";

import { ILunchDayDTO } from "../../../shared";

const props = defineProps<{
  day: ILunchDayDTO,
}>();

const images: Record<string, string> = {
  "4867978693705728": pork,
  "5958694228459520": vegan,
  "5712403623837696": vegetarian,
  "5430928647127040": swedish,
  "4749352483422208": lowCo,
  "6292284453683200": mediumCo,
  "4832794321616896": msc,
  "4727241205350400": fish,
  "5290191158771712": beef,
  "5677219251748864": organic,
  "6652174258929664": chicken,
  "5598430526177280": heavyLactos,
}

function getDayOfWeek(date: string) {
  const dayOfWeek = new Date(date).getDay();
  return isNaN(dayOfWeek) ? null : ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"][dayOfWeek];
}

function getImage(name: string): string {
  return images[name];
}
</script>
<style lang="scss">
@import "../../scss/base";

.meal {
  width: 100%;
  &__day {
    text-align: center;
    font-size: 26px;
  }
  &__content {
    margin-bottom: 10px;
    &__meal {
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #aaa;
      &__value {
        margin-bottom: 10px;
      }
      &__attributes {
        width: 20%;
        display: flex;
        gap: 10px;
        &__attribute {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
</style>