<template>
  <layout-component>
    <div class="departures">
      <div class="departures__header">
        <h1 class="departures__header__title">Departures</h1>
        <div class="departures__header__stations">
          <select class="departures__header__stations__select" v-model="selectedStation">
            <option v-for="(station, index) of favoriteStations" :selected="selectedStation.id === station.id" :value="station" :key="`station_${index}`">{{ station.name }}</option>
            <option v-if="isSelectedStationIsNotInFavoriteStations" :value="selectedStation">{{ selectedStation.name }}</option>
          </select>
          <div class="departures__header__stations__favorites">
            <template v-for="(station, index) of favoriteStations" :key="`station_${index}`">
              <button-component :button-type="isSelectedStation(station) ? 'primary' : 'secondary'" @click="selectedStation = station">{{ station.name }}</button-component>
            </template>
          </div>
          <div class="departures__header__stations__geo">
            <button-component class="departures__header__stations__geo__button" button-type="success" v-if="isSelectedStationIsNotInFavoriteStations">{{ selectedStation.name }}</button-component>
            <button-component @click="closest"><i class="fa-solid fa-location-crosshairs"></i></button-component>
          </div>
        </div>
      </div>
      <div class="departures__filter">
        <i :class="['departures__filter__icon', { 'departures__filter__icon--active': isFilterActive('bus') }, 'fa-solid fa-bus-simple']" @click="toggleFilter('bus')"></i>
        <i :class="['departures__filter__icon', { 'departures__filter__icon--active': isFilterActive('metro') }, 'fa-solid fa-train-subway']" @click="toggleFilter('metro')"></i>
        <i :class="['departures__filter__icon', { 'departures__filter__icon--active': isFilterActive('train') }, 'fa-solid fa-train']" @click="toggleFilter('train')"></i>
        <i :class="['departures__filter__icon', { 'departures__filter__icon--active': isFilterActive('tram') }, 'fa-solid fa-train-tram']" @click="toggleFilter('tram')"></i>
      </div>
      <div v-if="loading" class="departures__loading">
        <i class="fas fa-spinner fa-pulse" />
      </div>
      <div v-else class="departures__departure" v-for="(departure, index) of filteredDepartures" :key="`departure_${index}`">
        <departure-component :departure="departure"></departure-component>
      </div>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { ref, reactive, watch, onMounted, computed } from "vue";

// Bootstrap
import { apiService, modalService, storageService } from "../bootstrap";

// Components
import AlertComponent from './alert.component.vue';
import LayoutComponent from './layout.component.vue';
import DepartureComponent from './departure.component.vue';
import ButtonComponent from './button.component.vue';
import ClosestStationsComponent from './closest-stations.component.vue';

// DTO's
import { ISLStationDTO } from "../../../shared/dto/sl-station.dto";
import { ISLDepartureDTO } from "../../../shared/dto/sl-departure.dto";

const favoriteStations: ISLStationDTO[] = [
  { name: 'Rissne', id: '9323' },
  { name: 'T-Centralen', id: '9001' },
  { name: 'Medborgarplatsen', id: '9191' },
  { name: 'Karlaplan', id: '9222' },
]

const departures = ref<ISLDepartureDTO[]>([]);
const selectedStation = ref<ISLStationDTO>(storageService.get('sl-selected-station', favoriteStations[0]));
const loading = ref<boolean>(false);

function korv(value: unknown) {
  console.log(value);
}

const filteredDepartures = computed<ISLDepartureDTO[]>(() => {
  return departures.value.filter(departure => {
    if(departure.transportMode === "metro" && filter.metro) {
      return true;
    } else if(departure.transportMode === "bus" && filter.bus) {
      return true;
    } else if(departure.transportMode === "train" && filter.train) {
      return true;
    } else if(departure.transportMode === "tram" && filter.tram) {
      return true;
    }
  })
})
const isSelectedStationIsNotInFavoriteStations = computed<boolean>(() => {
  return !favoriteStations.some(station => station.id === selectedStation.value.id);
})

const filter = reactive<{ bus: boolean, metro: boolean, train: boolean, tram: boolean }>(storageService.get("sl-filter", { bus: true, metro: true, train: true, tram: true }))

function isSelectedStation(station: ISLStationDTO) {
  return station.id === selectedStation.value.id;
}

function isFilterActive(saerchFilter: 'bus' | 'metro' | 'train' | 'tram') {
  if(saerchFilter === "metro" && filter.metro) {
    return true;
  } else if(saerchFilter === "bus" && filter.bus) {
    return true;
  } else if(saerchFilter === "train" && filter.train) {
    return true;
  } else if(saerchFilter === "tram" && filter.tram) {
    return true;
  }
}

function toggleFilter(filterValue: 'bus' | 'metro' | 'train' | 'tram') {
  if(filterValue === "metro") {
    filter.metro = !filter.metro;
  } else if(filterValue === "bus") {
    filter.bus = !filter.bus;
  } else if(filterValue === "train") {
    filter.train = !filter.train;
  } else if(filterValue === "tram") {
    filter.tram = !filter.tram;
  }
  storageService.set("sl-filter", filter);
}

async function getDepartures(): Promise<ISLDepartureDTO[]> {
  loading.value = true;

  resetTimer();
  
  let departures: ISLDepartureDTO[] = [];
  try {
    departures = (await apiService.get<ISLDepartureDTO[]>(`/sl/departures?station=${selectedStation.value.id}`)).body;
  } catch {
    modalService.asPromise(AlertComponent, { message: "Could not fetch departures at the moment"});
  }
  loading.value = false;
  
  return removepassedDepartures(departures);
}

watch(selectedStation, async () => {
  storageService.set('sl-selected-station', selectedStation.value);
  departures.value = [];
  departures.value = await getDepartures();
})

onMounted(async () => {
  departures.value = await getDepartures();
  resetTimer();

  // Removes departures that has already passed
  setInterval(() => {
    departures.value = removepassedDepartures(departures.value);
  }, 1000);
});

function removepassedDepartures(departures: ISLDepartureDTO[]): ISLDepartureDTO[] {
  return departures.filter(departure => new Date(departure.expectedDeparture) > new Date());
}

let timer: number = 0;
function resetTimer() {
  window.clearTimeout(timer);
    // Refreshes departures every 5 minutes
    timer = window.setTimeout(async () => {
    departures.value = await getDepartures();
  }, 1000 * 60 * 5)
}

async function closest() {
  const station = await modalService.asPromise(ClosestStationsComponent) as ISLStationDTO;
  selectedStation.value = station;
}
</script>
<style lang="scss">
@import "../../scss/base";

.departures {
  width: 100%;
  &__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    &__title {
      display: flex;
      align-items: center;
      @include mobile {
        font-size: 20px;
      }
    }
    &__stations {
      display: flex;
      gap: 10px;
      &__select {
        height: 40px;
        @include desktop {
          display: none;
        }
      }
      &__favorites {
        @include desktop {
          display: flex;
          gap: 10px;
        }
        @include mobile {
          display: none;
        }
      }
      &__geo {
        display: flex;
        gap: 10px;
        &__button {
          @include mobile {
            display: none;
          }
        }
      }
    }
  }
  &__filter {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    &__icon {
      font-size: 30px;
      cursor: pointer;
      color: #aaa;
      &--active {
        color: #000;
      }
    }
  }
  &__loading {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
  }
  &__departure {
    padding: 5px 10px;
    &:nth-child(even) {
      background-color: #eee;
    }
  }
}
</style>