<template>
  <div class="departure">
    <div class="departure__info">
      <div class="departure__info__line">
        <i v-if="departure.transportMode === 'bus'" class="fa-solid fa-bus-simple"></i>
        <i v-if="departure.transportMode === 'metro'" class="fa-solid fa-train-subway"></i>
        <i v-if="departure.transportMode === 'train'" class="fa-solid fa-train"></i>
        <i v-if="departure.transportMode === 'tram'" class="fa-solid fa-train-tram"></i>
        <div :class="['departure__info__line__number', `departure__info__line__number--${lineColor(departure)}`]">
          {{  departure.lineNumber }}
        </div>
      </div>
      <div class="departure__info__destination">
        {{ departure.destination }}
      </div>
    </div>
    <div class="departure__time">
      {{ minutes }} min ({{ at }})
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { defineProps } from "vue";
  import { dateTimeToTime } from "../../../shared/utils/date.util";
  import { ISLDepartureDTO } from "../../../shared/dto/sl-departure.dto";

  const props = defineProps<{
    departure: ISLDepartureDTO
  }>();

  const minutes = Math.round((new Date(props.departure.expectedDeparture).getTime() - new Date().getTime()) / 1000 / 60);
  const at = dateTimeToTime(new Date(props.departure.expectedDeparture));

  function lineColor(departure: ISLDepartureDTO) {
    if(departure.transportMode === 'metro') {
      if(departure.lineNumber === '10' || departure.lineNumber === '11') {
        return 'blue';
      } else if(departure.lineNumber === '13' || departure.lineNumber === '14') {
        return 'red';
      } else if(departure.lineNumber === '17' || departure.lineNumber === '18' || departure.lineNumber === '19') {
        return 'green';
      }
    } else if(departure.transportMode === 'train') {
      return 'pink'
    } else if(departure.transportMode === 'tram') {
      return 'orange'
    }

    return 'red';
  }
</script>
<style lang="scss">
  .departure {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &__info {
      &__line {
        &__number {
          padding: 1px 4px;
          margin-left: 5px;
          display: inline-block;
          &--blue {
            background-color: #3865b2;
          }
          &--green {
            background-color: #007f30;
          }
          &--pink {
            background-color: #ee8292;
          }
          &--orange {
            background-color: #d3863a;
          }
          &--red {
            background-color: #d32927;
          }
          color: #fff;
        }
      }
      &__destination {
        font-size: 26px;
      }
    }
    &__time {
      display: flex;
      align-items: center;
    }
  }
</style>