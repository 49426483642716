// Libs
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

// Components
import LunchComponent from "./components/lunch.component.vue";
import SlComponent from "./components/sl.component.vue";

const routes: RouteRecordRaw[] = [
  { path: "/lunch", name: "lunch", component: LunchComponent },
  { path: "/sl", name: "sl", component: SlComponent },
  { path: "/", redirect: { name: "lunch" }}
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
});