<template>
  <div class="layout">
    <div class="layout__main">
      <slot />
    </div>
    <div class="layout__footer">
      <footer-component />
    </div>
  </div>
</template>
<script lang="ts" setup>
import FooterComponent from "./footer.component.vue";
</script>
<style lang="scss" scoped>
  .layout {
    height: 100vh;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    &__main {
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 20px;
      flex-grow: 1;
    }
    &__footer {}
  }
</style>